import { useSelector } from "react-redux";
import React from "react";

export function PublicInfo() {
    const user = useSelector((state) => state.user);

    return (
        <header>
            <ul>
                <li>Name: {user.username}</li>
                <li>Username: {user.username}</li>
                <li>Email: {user.email}</li>
            </ul>
        </header>
    );}
