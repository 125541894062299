import React, { useRef, useEffect } from 'react';
import {PublicInfo} from "./publicInfo";
import { useDispatch } from "react-redux";
import { addUser } from "../service/redux/userSlice"
const Dashboard = () => {

    const dispatch = useDispatch();
    const menuRef = useRef(null);

    const menuToggle = (event) => {
        menuRef.current.toggle(event);
    };



    useEffect(() => {
            dispatch(addUser(JSON.parse(localStorage.getItem("user"))));            
    });


    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 lg:col-4">
                    <PublicInfo />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
