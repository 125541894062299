import { createSlice } from "@reduxjs/toolkit";

// Objeto para el estado inicial

const initialState = {
    name: null,
    username: null,
    email: "",
};

// Reducers  → Acciones que queremos que realicen cambios en el state.

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action) => {
            const { name, username, email } = action.payload;
            state.name = name;
            state.username = username;
            state.email = email;
        },
        changeEmail: (state, action) => {
            state.email = action.payload; },  }, });

export const { addUser, changeEmail } = userSlice.actions;
export default userSlice.reducer;
