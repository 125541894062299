import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ChoferService from '../service/model/services/choferService';

const TablaPrueba = () => {


    const [choferes, setChoferes] = useState([]);

    useEffect(() => {
        refresh(); }, []);

    const refresh = () => {        
        ChoferService.getTablas1().then(chof => {
            setChoferes(chof);  
        });


    };
    

    return (
        <div className="grid">
            <div className="col-12">
                <Card title="Choferes">                    
                    <div className="p-card-body">
                        <DataTable value={choferes} tableStyle={{ minWidth: '60rem' }}>
                            <Column field="nombre" header="Nombre"></Column>
                            <Column field="apellidos" header="Apellidos"></Column>
                            <Column field="telefono" header="Telefono"></Column>
                            <Column field="transportista.razonsocial" header="Transportista"></Column>
                        </DataTable>
                    </div>
                    <div className="p-card-footer">Footer</div>
                </Card>
            </div>
        </div>
    );
};

export default TablaPrueba;
