import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


export const Login = () => {
    const navigate = useNavigate();


    const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      });

    useEffect(() => {
        localStorage.removeItem("user");
    });

    function login(e)
    {
        e.preventDefault();
        

        const form = e.target;
        const formData = new FormData(form);        
        //const API_URL = "http://80.240.126.211:8080/tms/api/auth/";
        const API_URL = "http://www.homura.es/api/auth/";
        const username = formData.get("username");
        const password = formData.get("password");


    return axiosInstance
    .post(API_URL + "signin", {
        username,
        password
    })
    .then((response) => {
      if (response.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data));
        navigate("/dashboard");

       
    }
      
    }); 
        
    }

    return (
        <div className="login-body">
            <div className="login-panel"></div>

            <div className="login-content">
                <img src="assets/layout/images/logo1.jpg" alt="babylon-layout" id="loginlogo"/>

                <h1>
                    <span>ACCESO A</span> TMS
                </h1>
                <p>Bienvenido, por favor rellene el formulario:</p>
                <form method="post" onSubmit={login}>
                <div className="login-input-wrapper">
                    <InputText placeholder="Usuario" name="username"/>
                    <i className="pi pi-user"></i>
                </div>

                <div className="login-input-wrapper">
                    <InputText placeholder="Clave" name="password"/>
                    <i className="pi pi-lock"></i>
                </div>

                <Button
                    label="Acceso"
                    type='submit'
                /></form>
            </div>
        </div>
    );
};
