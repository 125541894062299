import axios from "axios";
//const API_URL = "/tms/api/";

//depl
const API_URL = "http://www.homura.es/api/";



const getTablas = (pagina) => {
    if(pagina==null||pagina===":pagina") pagina=0;
    let choferList = axios.get(API_URL + "choferes/" + pagina);
    return choferList;
};

const getTablas1 = async () => {


    
    let config = {
        headers: {
            'Authorization': 'Bearer' + JSON.parse(localStorage.getItem("user")).token,
            'method': 'GET',
            'Access-Control-Allow-Origin': 'http://www.homura.es',
            'Access-Control-Allow-Headers': 'GET'
        }};
    

    //let choferList = axios.get(API_URL + 'choferes/all');
    //let choferList = axios.get(API_URL + 'choferes/all', config);
    
    let choferList = await axios.get(API_URL + 'choferes/all', config).then(response => {
          return response.data;  
    });

    return choferList;
    //const overThirty = choferList.filter(nombre => nombre !== '')

    //return axios.get(API_URL + "choferes/all").then((response) =>{ return response.data});
};

const delItemChofer = (item) => {

    console.log(item);

    fetch(API_URL + "choferes/del/" + item, { method: 'DELETE' })
        .then((response) => { return response;});
}


const ChoferService = {

    getTablas,
    getTablas1,
    delItemChofer
}

export default ChoferService;